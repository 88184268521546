import Controller from '@ember/controller';
import { inject } from '@ember/service';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class Application extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @inject isMobile;
  @inject router;
  @service('chatroom') chatService;
  constructor() {
    super(...arguments);
    try {
      if (window.self !== window.top) {
        console.log('is in iframe');
        this.set('isMobile.any', true);
      }
    } catch (e) {
      return true;
    }
    
    if (this.get('isMobile.any')) {
      document.body.className += ' ' + 'is--mobile';
    } else {
      document.body.className += ' ' + 'is--desktop';
    }
  }

  @action
  goToHome() {
    this.router.transitionTo('index');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    application: Application;
  }
}
