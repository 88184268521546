import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { waitForProperty } from 'ember-concurrency';
import { getOwner } from '@ember/application';

import { action } from '@ember/object';

export default class Login extends Controller.extend({
  // anything which *must* be merged to prototype here
}) {
  @service('services/amplify') amplify;
  @service('router') router;
  @service('datastore') datastoreService;
  @service router;

  email: string = '';
  firstname: string = '';
  lastname: string = '';
  username: string = '';
  password: string = '';
  queryParams: string[] = ['user', 'code', 'forgot'];
  forgot?: string;
  error: string = '';
  showCode: boolean = true;
  user: string = '';
  code?: string;
  showResetPassword = false;

  constructor() {
    super(...arguments);

    this.activate.perform();
  }
  @task
  *resetPassword() {
    try {
      if (this.password !== this.passwordwdh) {
        this.set('error', this.intl.t('ts.pass_not_identical'));
        return;
      }
      if (this.password.length < 6) {
        this.set('error', this.intl.t('ts.pass_too_short'));
        return;
      }
      const Auth = this.amplify.Auth;
      console.log({
        username: this.username.toLowerCase().trim(),
        code: this.code,
        password: this.password,
      });
      const confirmUser = yield Auth.forgotPasswordSubmit(
        `${this.user.toLowerCase().trim()}`,
        this.code,
        this.password
      );
      this.set('success', this.intl.t('ts.pass_saved'));
      this.set('showResetPassword', false);
    } catch (e) {}
  }
  @task
  *activate() {
    yield waitForProperty(this, 'code', (c) => c !== undefined);
    const Auth = this.amplify.Auth;
    try {
      if (this.forgot !== undefined) {
        this.set('showResetPassword', true);
      } else {
        const confirmUser = yield Auth.confirmSignUp(
          `${this.user.toLowerCase().trim()}`,
          this.code
        );
        this.set('success', this.intl.t('ts.user_activated_login'));
      }
    } catch (e) {
      if (
        e.message === 'User is already confirmed.' ||
        e.message === 'User cannot be confirmed. Current status is CONFIRMED'
      ) {
        this.set('success', this.intl.t('ts.user_activated_login'));
      } else {
        this.set('error', this.intl.t('ts.link_expired'));
        yield Auth.resendSignUp(this.user);
      }
    }
  }
  @task
  *setNewPassword() {
    try {
      const Auth = this.amplify.Auth;
      if (this.password !== this.passwordwdh) {
        this.set('error', this.intl.t('ts.pass_not_identical_2'));
        return;
      }
      if (this.password.length < 6) {
        this.set('error', this.intl.t('ts.pass_too_short'));
        return;
      }
      let user = yield Auth.currentAuthenticatedUser();
      yield Auth.changePassword(user, this.oldpassword, this.password);
      yield Auth.updateUserAttributes(user, {
        'custom:pw': 'no',
      });
      let route = getOwner(this).lookup(`route:application`);
      yield route.refresh();
      this.transitionToRoute('application');
    } catch (e) {
      console.error(e);
    }
  }

  @task
  *login() {
    let kuerzel = this.amplify.currentClient.kuerzel;
    if (!kuerzel) {
      kuerzel = '';
    }
    const ExtUsername = `${kuerzel}${this.username.toLowerCase().trim()}`;
    const Auth = this.amplify.Auth;
    try {
      const authUser = yield Auth.signIn(ExtUsername, this.password);
      let ses = yield Auth.currentSession();
      let user = yield Auth.currentAuthenticatedUser({
        // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        bypassCache: false,
      });
      console.log(ses, user);
      yield this.datastoreService.stopDatastore();
      yield this.datastoreService.startDatastore();
      this.set('username', '');
      yield this.amplify.initSession();
      this.router.transitionTo('application');
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case 'UserNotFoundException':
          this.set('error', this.intl.t('ts.user_not_found'));
          yield Auth.resendSignUp(ExtUsername);
          break;
        case 'NotAuthorizedException':
          if (e.message == 'Incorrect username or password.') {
            this.set('error', this.intl.t('ts.wrong_password'));
          } else if (e.message == 'Password attempts exceeded') {
            this.set('error', this.intl.t('ts.too_many_retries'));
          } else {
            this.set('error', this.intl.t('ts.reset_your_pw'));
          }
          break;
        case 'UserNotConfirmedException':
          this.set('error', this.intl.t('ts.user_not_found2'));
          yield Auth.resendSignUp(ExtUsername);
          break;

        default:
          this.set('error', this.intl.t('ts.login_error'));
      }
    }
  }

  @task
  *resendInviteEmail(email: string) {
    try {
      const Auth = this.amplify.Auth;

      const user = yield Auth.forgotPassword(
        `${this.amplify.currentClient.kuerzel}${this.username
          .toLowerCase()
          .trim()}`
      );
      if (user.CodeDeliveryDetails) {
        console.log(user);
        this.set('success', this.intl.t('ts.resend_invite'));
      } else {
      }
    } catch (e) {
      this.set('error', this.intl.t('ts.user_not_found'));
      return;
    }
  }

  @action async logout() {
    await this.amplify.logout();
    window.location.reload();
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    nouser: Nouser;
  }
}
