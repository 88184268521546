import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import Amplify from '@aws-amplify/core';
import config from 'event-app/config/environment';
let root_domain;
if (location.host.includes('localhost')) {
  let temp = location.host.split('.').reverse();
  root_domain = 'localhost';
} else if (location.href.includes('192.168.8.161')) {
  root_domain = '192.168.8.161';
} else {
  let temp = location.host.split('.').reverse();
  root_domain = '.' + temp[1] + '.' + temp[0];
}
console.log(config.awsmobile.Auth);
config.awsmobile.Auth.cookieStorage.domain = `${root_domain}`;
Amplify.configure(config.awsmobile);
const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver,
});

loadInitializers(App, config.modulePrefix);

export default App;
