import Component from '@ember/component';
import {
  classNames,
  className,
  attribute,
  tagName,
} from '@ember-decorators/component';
import { inject as service } from '@ember/service';

import { action, computed } from '@ember/object';

import { sort } from '@ember/object/computed';
@tagName('nav')
@classNames('app-nav')
export default class AppNav extends Component.extend({
  // anything which *must* be merged to prototype here
}) {
  @className('open', 'closed') open: boolean = false;
  @service('chatroom') chatService;
  @service('router') router;

  @service('services/amplify') amplify;
  @action
  toggleNav() {
    this.toggleProperty('open');
  }

  // using descending sort
  sortConf = ['sorting:asc'];
  @sort('activeModules', 'sortConf') sortedModules;

  @action
  async logout() {
    await this.amplify.logout();
    window.location.reload();
  }
  @computed('amplify.currentTheme.config.moduleConfig.[]')
  get modules() {
    if (!this.get('amplify.currentTheme.config.moduleConfig')) {
      return [];
    }
    let mods = this.get('amplify.currentTheme.config.moduleConfig');
    mods = mods.filterBy('showInEvent', true);
    if (this.isMobile.any) {
      mods = mods.filterBy('showInMobile', true);
    }
    if (!this.isMobile.any) {
      mods = mods.filterBy('showInDesktop', true);
    }
    mods.sort((a: any, b: any) =>
      parseInt(a.sorting) > parseInt(b.sorting)
        ? 1
        : parseInt(b.sorting) > parseInt(a.sorting)
        ? -1
        : 0
    );
    return mods;
  }
  @computed('modules')
  get activeModules() {
    if (!this.get('modules')) {
      return [];
    }
    let mods = this.get('modules');
    mods = mods.filterBy('showInEvent', true);
    return mods.map((mod) => {
      return {
        key: mod.componentName,
        header: mod.name,
        icon: mod.icon,
        iconname: mod.iconname,
        tooltip: mod.tooltip,
        routeName: mod.routeName,
        sorting: mod.sorting,
        groups: mod.groups,
        external: mod.external,
        link: mod.link,

        showInDesktop: mod.showInDesktop,
        showInEvent: mod.showInEvent,
        showInMeeting: mod.showInDesktop,
        showInMobile: mod.showInMobile,
        showInStream: mod.showInStream,
      };
    });
  }
  @action
  click(module) {
    if (module.routeName === 'videocall') {
      let user = this.amplify.currentUser;
      window.open(
        `${this.amplify.currentTheme.config.meetingUrl}?userid=${user.id}`,
        '_blank'
      );
      return;
    }
    if (module.external) {
      let link = module.link
        .replace('{{code}}', this.amplify.currentUser.invitecode)
        .replace('{{username}}', this.amplify.currentUser.username);
      window.open(`${link}`, '_blank');
      return;
    }
    this.get('router').transitionTo(module.routeName);
  }
}
