import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class Application extends Route.extend({
  // anything which *must* be merged to prototype here
}) {
  @service() moment;
  @service('services/amplify') amplify;

  headTags?: object[];

  target: string = '';

  isInitialized = false;

  async beforeModel(transition) {
    try {
      if (!this.isInitialized) {
        await this.amplify.initAmplify();

        await this.amplify.initClient();

        await this.amplify.initSession();
      }

      this.set('isInitialized', true);

      this.set('amplify.target', transition.to.localName);
      let firstVisit = localStorage.getItem('firstvisit');
      console.log(transition);

      if (
        firstVisit !== '1' &&
        transition.to.name == 'index' &&
        this.amplify.currentClient.loginmode !== 'ANONYM'&&
        this.amplify.currentClient.loginmode !== 'QUESTIONNAIRE'
      ) {
        return this.transitionTo('landing');
      }
      if (
        !this.get('amplify.isAuthenticated') &&
        transition.to.name !== 'login'&&
        this.amplify.currentClient.loginmode !== 'ANONYM' &&
        this.amplify.currentClient.loginmode !== 'QUESTIONNAIRE'
      ) {
        this.transitionTo('landing');
        return;
      }
      if (
        !this.get('amplify.isAuthenticated') &&
        this.amplify.currentClient.loginmode === 'QUESTIONNAIRE'
      ) {
        this.transitionTo('landing');
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async model() {
    console.log('HIER');
    await this.setHeadTags(
      this.amplify.currentClient,
      this.amplify.currentTheme
    );
    return this.amplify.currentClient;
  }

  async setHeadTags(model, theme) {
    let headTags = await this.amplify.setHeadTags(model, theme);
    this.set('headTags', headTags);
    return headTags;
  }
}
