import Application from '@ember/application';

export function initialize(application: Application): void {
  console.log(application.hasRegistration('service:services/amplify'));
  application.inject('controller', 'amplify', 'service:services/amplify');
  application.inject('component', 'amplify', 'service:services/amplify');
  application.inject('route', 'amplify', 'service:services/amplify');
  application.inject('controller', 'intl', 'service:intl');
  application.inject('component', 'intl', 'service:intl');
  application.inject('route', 'intl', 'service:intl');
}

export default {
  initialize,
};
